import { useCallback, useState } from 'react';

export const useOffsetPagination = ({ requestFn, maxResults }) => {
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchItems = useCallback(async () => {
    if (items.length > 0 && items.length === totalItems) return;

    setLoading(true);
    try {
      const response = await requestFn({ offset });
      const payload = await response.json();

      if (!response.ok) {
        const error = new Error('Error fetching items');
        error.status = response.status;
        throw error;
      }

      const { results, totalResults } = payload;
      setTotalItems(totalResults);
      setItems(prevData => [...prevData, ...results]);
      setOffset(prevOffset => prevOffset + maxResults);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [items, offset, totalItems, requestFn, maxResults]);

  return {
    items,
    totalItems,
    loading,
    error,
    fetchItems
  };
};
