import { branch, compose, lifecycle, renderNothing, withHandlers, withPropsOnChange } from 'recompose';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { MIN_DELAY, STAGES } from '../constants';
import { helpers } from '../../../../api/helpers';
import { mapAddressAsSelectItem } from '../../../../helpers/property-portfolio/property-address';
import { InputSelect as Select } from '../../../../components/inputs/select/v2-select';
import { withPagination } from '../../../../helpers/hocs/with-pagination-handler';

export const AddressSelect = compose(
  withHandlers({
    getPaginatedItems:
      ({ postcode, maxResults, onLookUp }) =>
      async ({ offset = 0 }) => {
        onLookUp(true, STAGES.ADDRESS_LOOKUP);
        const response = await PromiseShim.unless(
          helpers.getPropertiesByPostcode({ value: postcode, offset, maxResults }),
          MIN_DELAY
        );

        onLookUp(false, STAGES.READY);
        return response;
      }
  }),
  withPagination,
  withPropsOnChange(['items'], ({ items }) => ({ items: items.map(mapAddressAsSelectItem) })),
  withHandlers({
    onScrollBottom:
      ({ fetchPaginatedItems }) =>
      () =>
        fetchPaginatedItems()
  }),
  lifecycle({
    async componentDidMount() {
      const { onLookUp, fetchPaginatedItems, errorMessages } = this.props;

      try {
        await fetchPaginatedItems();
      } catch (error) {
        onLookUp(false, STAGES.DEFAULT, {
          key: 'postcode',
          value: errorMessages?.noAddresses
        });
      }
    },
    componentDidUpdate(prevProps) {
      const { items, errorMessages, onLookUp } = this.props;

      if (prevProps.items !== items && items.length < 1) {
        return onLookUp(false, STAGES.DEFAULT, {
          key: 'postcode',
          value: errorMessages?.noAddresses
        });
      }
    }
  }),
  branch(({ items }) => items.length < 1, renderNothing)
)(Select);
