import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { createCustomPageProps } from '../../../helpers/utils';
import { Question } from '../index';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { InputRadioGroup } from '../../../components/inputs/radio/radio-group';
import {
  ACCOUNT_TYPES,
  NO_MORE_ACCOUNTS_KEY,
  REQUIRED_PROPS_COUNT_LISA,
  DEFAULT_ACCOUNTS_PAGE_MESSAGE,
  getAccountTypes,
  hasAccount as hasAccountFunc
} from '../../../helpers/deposit-builder/accounts';
import { sendAccounts } from '../../../store/reducers/user-attributes/actions';
import { enhanceName } from '../helpers/prettify-name';
import { ConfigService } from '../../../services/config-service';

export const DepositBuilderAccounts = React.memo(
  ({ onClick, accounts, disabled, hasAccount, message, selectedAccountType, setSelectedAccountType, ...rest }) => {
    const ACCOUNT_TYPES_DATA = getAccountTypes();
    const noMoreAccountsOption = {
      name: NO_MORE_ACCOUNTS_KEY,
      value: NO_MORE_ACCOUNTS_KEY,
      label: 'That’s it, no more accounts'
    };

    const radioItems = [
      ...(hasAccount ? [noMoreAccountsOption] : []),
      ...Object.keys(ACCOUNT_TYPES_DATA).map(key => ({
        name: key,
        value: key,
        label: ACCOUNT_TYPES_DATA[key],
        disabled: disabled(key)
      }))
    ];

    const handleTypeChange = selectedItem => () => {
      setSelectedAccountType(selectedItem.name);
    };

    return (
      <Question message={message} {...rest}>
        {() => (
          <>
            <InputRadioGroup
              plainValue={true}
              items={radioItems}
              value={selectedAccountType}
              onChange={handleTypeChange}
            />
            <ButtonWithIcon
              disabled={!selectedAccountType}
              onClick={onClick({ type: selectedAccountType, title: ACCOUNT_TYPES_DATA[selectedAccountType] })}
              type='major'
            >
              Continue
            </ButtonWithIcon>
            {!hasAccount && (
              <ButtonWithIcon to='/checklist/deposit-builder/existing-savings' type='minor'>
                I haven’t started saving yet
              </ButtonWithIcon>
            )}
          </>
        )}
      </Question>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const params = createCustomPageProps(ownProps.location.pathname);

  return {
    accounts: state.userAttributes.accounts.items,
    match: { params: { activity: params.name, slug: params.trigger } }
  };
};

export const DepositBuilderAccountsQuestion = compose(
  connect(mapStateToProps, { sendAccounts }),
  withState('selectedAccountType', 'setSelectedAccountType', ''),
  withProps(({ accounts }) => {
    const hasAccount = hasAccountFunc(accounts);

    const { noAccountsMessage, hasAccountsMessage } = ConfigService.get('DEPOSIT_BUILDER.ACCOUNTS_PAGE', {
      noAccountsMessage: DEFAULT_ACCOUNTS_PAGE_MESSAGE.noAccounts,
      hasAccountsMessage: DEFAULT_ACCOUNTS_PAGE_MESSAGE.hasAccounts
    });

    const message = hasAccount ? hasAccountsMessage : noAccountsMessage;

    return { message, hasAccount };
  }),
  withHandlers({
    onClick:
      ({ sendAccounts, history, accounts }) =>
      item =>
      async () => {
        const previousState = accounts;
        const type = item.type;
        const name = item.title;
        const prettifiedName = enhanceName(previousState, '-')(type);

        switch (type) {
          case NO_MORE_ACCOUNTS_KEY: {
            history.push('/checklist/deposit-builder/location');
            break;
          }
          case 'others': {
            history.push('/checklist/deposit-builder/name-your-account');
            break;
          }
          default: {
            await sendAccounts({
              items: {
                ...previousState,
                [prettifiedName]: { ...previousState[prettifiedName], name, type }
              }
            });

            history.push(`/checklist/deposit-builder/total-in-account/${prettifiedName}`);
          }
        }
      },
    disabled:
      ({ accounts }) =>
      type => {
        if (type === ACCOUNT_TYPES.LISA || type === ACCOUNT_TYPES.HTB) {
          return Object.values(accounts).some(
            item => item.type === type && Object.keys(item).length === REQUIRED_PROPS_COUNT_LISA
          );
        }

        return false;
      }
  })
)(DepositBuilderAccounts);
