import React from 'react';
import { useOffsetPagination } from '../../hooks/useOffsetPagination';

export const withPagination =
  Component =>
  ({ ...props }) => {
    const { items, totalItems, error, fetchItems } = useOffsetPagination({
      requestFn: props.getPaginatedItems,
      maxResults: props.maxResults
    });

    return (
      <Component {...props} items={items} error={error} totalItems={totalItems} fetchPaginatedItems={fetchItems} />
    );
  };
